import { request } from '@/api/request';

// //权限池列表
// export const permissionList = data => {
// 	return request('get','/api/permission/permissionList',data);
// }

//------------------------------------------------------------------------

//角色列表
export const getRoleList = data => {
	return request('get','/api/permission/getRoleList',data);
}

//获取角色权限
export const getRolePermissionList = data => {
	return request('get','/api/permission/getRolePermissionList',data);
}

// 角色编辑/添加角色
export const addOrEditRole = data => {
	return request('post','/api/permission/addOrEditRole',data);
}

//删除角色
export const delRole = data => {
	return request('get','/api/permission/delRole',data);
}

//权限池列表
export const permissionList = data => {
	return request('get','/api/permission/permissionList',data);
}

//权限池添加
export const addPermission = data => {
	return request('post','/api/permission/addPermission',data);
}

//权限池编辑
export const editPermission = data => {
	return request('post','/api/permission/editPermission',data);
}

//获取账号列表
export const platformAccountList = data => {
	return request('get','/api/accountPlatform/platformAccountList',data);
}

//平台账号创建
export const createPlatformAccount = data => {
	return request('post','/api/accountPlatform/createPlatformAccount',data);
}

//删除账号信息
export const remPlaformAccount = data => {
	return request('get','/api/accountPlatform/remPlaformAccount',data);
}

//修改平台登录者账号信息
export const revampPlatformAccountInfo = data => {
	return request('post','/api/accountPlatform/revampPlatformAccountInfo',data);
}

//平台账号密码修改
export const modifyPassword = data => {
	return request('post','/api/Account/PlatformAdmin/modifyPassword',data);
}
