<template>
    <div class="head">
        <!-- <div class="collapse" @click="collapse">
            <i v-if="isCollapse" class="el-icon-s-unfold"></i>
            <i v-else class="el-icon-s-fold"></i>
        </div> -->
        <!-- 折叠 需要打开左侧导航栏图标 -->

        <div class="name">
            
            <div v-if="$route.path=='/index'">
            <img src="../assets/store_logo.png" alt="">
            <span>{{title}}</span>
            </div>
            <div v-else class="goIndex">
            <img src="../assets/left_blue.png" alt="" style="width: 10px;height: 18px;">
                <el-button type="text" @click.native="homes" ><span style="font-size: 20px;">返回工作台</span></el-button>
            </div>
           
        </div>
        <div class="breadcrumb">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item  v-for="(item,index) in breadlitst" :key="index">{{ item }}</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="breadcrumb">
        </div>
        <el-dropdown>
            <div class="user">
                <!-- <div class="xiaoxi" @click="isCustomer = true">
                    <i class="el-icon-chat-line-square"><span>2</span></i>
                </div> -->
                <div class="sub-title">{{ username||'管理员' }}</div>
                <div class="block">
                    <!-- <el-avatar size="medium" src="https://tszj.oss-cn-hangzhou.aliyuncs.com/pc/6584f7232f112938345943.jpg"></el-avatar> -->
                    <img :src="head_portrait ? head_portrait : '../assets/head.png'" alt="">
                </div>
            </div>
            <el-dropdown-menu slot="dropdown">
                <el-dropdown-item @click.native="homes"><i class="el-icon-s-home"></i>首页</el-dropdown-item>
                <!-- <el-dropdown-item @click.native="personal"><i class="el-icon-user"></i>个人中心</el-dropdown-item> -->
                <el-dropdown-item @click.native="editpassword"><i class="el-icon-edit"></i>修改密码</el-dropdown-item>
                <el-dropdown-item divided @click.native="exits"><i class="el-icon-circle-close"></i>退出</el-dropdown-item>
            </el-dropdown-menu>
        </el-dropdown>

        <el-drawer
            title="个人中心"
            :visible.sync="drawer"
            direction="rtl"
            :before-close="handleClose"
            size="480px"
        >
            
            <div class="person">

                <el-form :label-position="labelPosition" label-width="80px" :model="formLabelAlign" :hide-required-asterisk="true">
                    <el-form-item label="用户头像 :">
                        <!-- <el-image 
                            style="width: 100px; height: 100px"
                            :src="url" 
                            :preview-src-list="srcList">
                        </el-image> -->
                        <el-avatar shape="square" :size="100" fit="fit" :src="url"></el-avatar>
                    </el-form-item>
                    <el-form-item label="用户姓名 :">
                        <el-input v-model="formLabelAlign.name"></el-input>
                    </el-form-item>
                    <el-form-item label="性别 :">
                        <el-select v-model="formLabelAlign.sex" placeholder="请选择性别" style="width:100%;">
                            <el-option label="男" value="nan"></el-option>
                            <el-option label="女" value="nv"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="联系方式 :">
                        <el-input v-model="formLabelAlign.tel"></el-input>
                    </el-form-item>
                    <el-form-item label="邮箱 :">
                        <el-input v-model="formLabelAlign.mailbox"></el-input>
                    </el-form-item>
                    <el-form-item label="居住地址 :">
                        <el-input v-model="formLabelAlign.address"></el-input>
                    </el-form-item>
                </el-form>

            </div>
            
                <div class="bottons">
                    <el-row type="flex" justify="end">
                        <el-col :span="5"><el-button @click="drawer = false">取消</el-button></el-col>
                        <el-col :span="5"><el-button type="primary" @click="drawer = false">确认</el-button></el-col>
                    </el-row>
                </div>
            
        </el-drawer>

        <!-- 修改密码 -->
        <el-dialog title="管理员设置" :visible.sync="isAuth" width="600px">
            <div style="margin: 0 50px 0 0">
                <el-form :model="passwordForm" ref="passwordForm" label-width="120px" class="demo-ruleForm">
                    <el-form-item label="员工名称：">
                        <span>张三</span>
                    </el-form-item>
                    <el-form-item label="系统账号：">
                        <span>172637363732</span>
                    </el-form-item>
                    <el-form-item label="原密码：" prop="password" :rules="[{ required: true, message: '密码不能为空'}]">
                        <el-input v-model="passwordForm.password" show-password autocomplete="off"></el-input>
                    </el-form-item>
                    <el-form-item label="修改密码：" prop="change_password" :rules="[{ required: true, message: '新密码不能为空'}]">
                        <el-input v-model="passwordForm.change_password" show-password autocomplete="off"></el-input>
                    </el-form-item>
                    <el-form-item label="确认密码：" prop="confirm_password" :rules="[{ required: true, message: '新密码不能为空'}]">
                        <el-input v-model="passwordForm.confirm_password" show-password autocomplete="off"></el-input>
                    </el-form-item>
                </el-form>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="isAuth = false" size="small">取 消</el-button>
                <el-button type="primary" size="small" @click="adminPassword('passwordForm')">确 定</el-button>
            </span>
        </el-dialog>
        <!-- 修改密码 -->

        <!-- 消息客服 -->
        <!-- <el-dialog :visible.sync="isCustomer" width="670px">
            <div class="kefu">
                <div class="kefu_left">
                    <img src="../assets/QRBHS.jpg" alt="">
                </div>
                <div class="kefu_right">
                    <p>客服联系电话：18273839381</p>
                    <p>工作时间</p>
                    <span>周一至周五：09:00-12:00 12:30-18:00</span>
                </div>
            </div>
        </el-dialog> -->
        <!-- 消息客服 -->

    </div>
</template>

<script>
import { mapState } from 'vuex';
import {modifyPassword} from '@/api/permission';
import CryptoJS from 'crypto-js';
export default {
    name: "Header",
    props:['title'],
    data() {
        return {
            drawer:false,   //个人中心
            url: 'https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg',
            srcList: [
                'https://fuss10.elemecdn.com/8/27/f01c15bb73e1ef3793e64e6b7bbccjpeg.jpeg',
                'https://fuss10.elemecdn.com/1/8e/aeffeb4de74e2fde4bd74fc7b4486jpeg.jpeg'
            ],
            labelPosition: 'right',
            formLabelAlign: {
                name: '小天',
                sex: '男',
                tel: '17682310521',
                mailbox:'728682378@qq.com',
                address:'杭州市拱墅区拱宸桥东'
            },
            isAuth:false,    //修改密码弹窗
            passwordForm:{
                password:'',    //密码
                change_password:'', //新密码
                confirm_password:'',    //新密码
            },
            breadlitst:[],  //面包屑数据

            isCustomer:false,  //客服
            username:localStorage.getItem('username'), //用户名
            head_portrait:localStorage.getItem('head_portrait')
        }
    },
    created() {
        // console.log(111);
    },
    computed:{
        isCollapse(){  //展开列表
            return this.$store.state.isCollapse
        }
    },
    watch:{
        $route:{
            handler(route){
                let breadlitst = [];
                let List = route.matched;
                List.forEach(item => {
                    breadlitst.push(item.meta.title)
                });
                const uniqueArr = Array.from(new Set(breadlitst));
                this.breadlitst = uniqueArr;
            },immediate:true
        }
    },
    mounted() { },
    methods: {
        collapse(){
            this.$store.commit('collapse',!(this.isCollapse))
        },
        homes(){    //首页
            localStorage.removeItem('activeMenu');  //清楚左侧导航缓存
            localStorage.setItem('activeMenu','1'); //返回首页设置下表为1
            this.$store.commit('activeMenus','1');   //修改vuex里面的数据
            this.$router.push({path:'/index'});
        },
        personal(){ //个人中心
            this.drawer = true;
        },
        handleClose(){  //关闭个人弹窗
            this.drawer = false;
        },
        editpassword(){ //修改密码
            this.isAuth = true;
            this.passwordForm.password = '';
            this.passwordForm.change_password = '';
            this.passwordForm.confirm_password = '';
        },
        adminPassword(formName){    //修改密码
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    let data = _.cloneDeep(this.passwordForm);
                    if(data.change_password != data.confirm_password){
                        this.$message({
                            type: 'error',
                            message: '请查看密码是否一致！'
                        });
                        return
                    }
                    data.password = CryptoJS.SHA256(data.password).toString();
                    data.change_password = CryptoJS.SHA256(data.change_password).toString();
                    data.confirm_password = CryptoJS.SHA256(data.confirm_password).toString();
                    // delete data.confirm_password;
                    modifyPassword(data).then(res=>{
                        this.$message({
                            type: 'success',
                            message: '管理员密码修改成功!'
                        });
                        this.isAuth = false;
                        setTimeout(()=>{
                            this.$router.push({ path: '/login' });
                        }, 3000 )
                    }).catch(err=>{})
                } else {
                    return false;
                }
            });
        },
        exits(){    //退出
            this.$confirm('是否确认继续退出该程序?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    localStorage.removeItem('activeMenu');  //清楚左侧导航缓存
                    localStorage.removeItem('hasToken');
                    this.$router.push({ path: '/login' });
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消操作'
                    });          
            });
        }
    },
};
</script>
<style lang="less" scoped>
.head{
    height: 100%;
    display: flex;
    align-items: center;
    background: #f7f8fa;
    padding: 0 20px;
    .name{
        img{
            width: 20px;
            height: 18px;
            margin-right: 10px;
        }
        span{
            font-size: 18px;
            font-weight: 500;
            color: #639AFF;
        }
        .goIndex{
            display: flex;
            align-items: center;
            font-size: 24px;
        }
    }
    /deep/.el-dialog{
        border-radius: 16px;
    }
    .person{
        height: 90%;
        margin: 0 30px;
    }
    /deep/.el-drawer__header{
        font-weight: bold;
        font-size: 18px;
        color: #333;
        border-bottom: 1px solid #f1f1f1;
    }
    .bottons{
        border-top: 1px solid #f1f1f1;
    }
    .breadcrumb{
        margin-left: 50px;
        /deep/.el-breadcrumb__item{
            font-size: 18x;
        }
    }
    .collapse{
        flex: 1;
        .el-icon-s-fold,.el-icon-s-unfold{
            font-size: 24px;
            cursor: pointer;
        }
        .el-icon-s-fold:hover,.el-icon-s-unfold:hover{
            color: #1989fa;
        }
    }
    /deep/.el-dropdown{
        margin-left: auto;
    }
    .user{
        height: 60px;
        text-align: right;
        min-width: 300px;
        margin-left: auto;
        .xiaoxi{
            display: inline-block;
            margin: 0px 20px 0 0;
            vertical-align: middle;
            i{
                font-size: 24px;
                position: relative;
            }
            span{
                width: 15px;
                height: 15px;
                text-align: center;
                line-height: 15px;
                font-size: 12px;
                color: #ffffff;
                border-radius: 20px;
                background: #FF5D5B;
                position: absolute;
                top: -5px;
                right: -10px;
            }
        }
        .sub-title{
            display: inline-block;
            line-height: 60px;
        }
        .block{
            float: right;
            margin: 12px 0 0 10px;
            img{
                width: 36px;
                height: 36px;
                border-radius: 36px;
            }
        }
    }
    .user:hover{
        cursor: pointer;
    }

    .kefu{
        margin: 0 62px 0;
        display: flex;
        line-height: 20px;
        align-items: center;
        height: 174px;
        .kefu_left{
            width: 174px;
            height: 174px;
            margin-right: 24px;
            img{
                width: 174px;
                height: 174px;
            }
        }
        .kefu_right{
            font-size: 16px !important;
            font-weight: 500;
            color: #333333;
            p:nth-child(1){
                margin-top: -15px;
            }
            span{
               color: #666666; 
            }
        }
    }


}
</style>