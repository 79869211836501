import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {  //用来存储数据
    isCollapse:false, //右侧导航栏默认展开
    permissions:['add','edit','del','share','upload','information','warn','seeLogistics','seeOrderDetail'], //按钮权限控制组
    activeMenu:'', //左侧导航默认下表
  },
  mutations: {  //数据操作
    collapse(state,payload){  //右侧导航折叠
      state.isCollapse = payload;
    },
    activeMenus(state,payload){ //左侧导航栏下表变化
      console.log("000",payload)
      state.activeMenu = payload;
    }
  },
  getters: {  //用来将state数据进行加工
    
  },
  actions: {  //相应组件中的事件

  },
  modules: {

  }
})

// seeLogistics 查看物流
// seeOrderDetail 查看订单详情